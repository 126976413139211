body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.react-time-picker__wrapper {
  border: none !important;
}

.pt-04rem {
  padding-top: 0.34rem !important;
  padding-bottom: 0.34rem !important;
}

i {
  font-style: normal !important;
}

p::first-letter {
  text-transform: capitalize;
}

.firstCap a::first-letter {
  text-transform: capitalize !important;
}

.pd-0 input {
  padding: 1rem;
}

.Toastify__toast-container {
  z-index: var(--toastify-z-index);
  position: absolute;
  padding: 4px;
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff;
  transform: translate(-50%, -50%) !important;
  top: 50% !important;
  left: 50% !important;
  bottom: auto !important;
  right: auto !important;
  width: 400px !important;
}

.overlay111 {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  transition: 0.2s all;
}

#_checkbox {
  display: none;
}

.tickLabel {
  width: 1.25rem;
  margin-bottom: 0.15rem;
  height: 1.25rem;
  display: inline-table !important;
  background-color: #f72414;
  vertical-align: middle;
  border-radius: 50%;
  box-shadow: 0 5px 7px #ffbeb8;
  cursor: pointer;
  transition: 0.2s ease transform, 0.2s ease background-color, 0.2s ease box-shadow;
  overflow: hidden;
  z-index: 1;
  border: 1px solid rgba(0, 0, 0, 0.44);
}

.tickLabel:hover:before {
  width: 55px;
  height: 55px;
  box-shadow: inset 0 7px 10px #ff9d96;
}

.tickLabel:active {
  transform: translateY(-15%) scale(0.9);
}

#tick_mark {
  position: absolute;
  top: -1px;
  right: 0;
  left: 0;
  width: 60px;
  height: 60px;
  margin: 0 auto;
  margin-left: 14px;
  transform: rotateZ(-40deg);
}

#tick_mark:before,
#tick_mark:after {
  content: "";
  position: absolute;
  background-color: #fff;
  border-radius: 2px;
  opacity: 0;
  transition: 0.2s ease transform, 0.2s ease opacity;
}

#tick_mark:before {
  left: 0;
  bottom: 0;
  width: 10px;
  height: 30px;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.23);
  transform: translateY(-68px);
}

#tick_mark:after {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 10px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.23);
  transform: translateX(78px);
}

#_checkbox:checked+.tickLabel {
  background-color: #07d410;
  box-shadow: 0 5px 7px #92ff97;
}

#_checkbox:checked+.tickLabel:before {
  width: 0;
  height: 0;
}

#_checkbox:checked+.tickLabel #tick_mark:before,
#_checkbox:checked+.tickLabel #tick_mark:after {
  transform: translate(0);
  opacity: 1;
}

.crossCircle::before {
  background: #4488bf;
  border-radius: 50%;
  color: white;
}

.crossCircle {
  font-size: 1.5rem;
}

.selected button.page-link {
  background: #265999 !important;
  color: white;
}

.page-link {
  background: #26599929;
  color: black;
  padding: 8px 20px;
  font-weight: 700;
}

.page-link:hover {
  background: #3e70ac;
  color: white;
}

.driverPagination a {
  text-decoration: none;
}

/*======================
    404 page
=======================*/

.page_404 {
  padding: 40px 0;
  background: #fff;
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 60vh;
  background-position: center;
  background-size: cover;
}

.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #4488bf;
  text-decoration: none;
  margin: 20px 0;
  display: inline-block;
}

.contant_box_404 {
  margin-top: -50px;
}

.err404 {
  width: 50%;
  margin: 0 auto;
  text-align: center;
}

/*======================
    404 page end
=======================*/

.mod {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 30px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 1rem 1rem 1rem 100rem rgba(0, 0, 0, 0.15), 0px 20px 20px rgba(0, 0, 0, 0.1);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.otpInputBox {
  /* height: 50vh; */
  display: flex;
  justify-content: center;
  align-items: end;
}

.otpInputBox>form>div {
  display: flex !important;
  justify-content: space-evenly !important;
  align-items: center !important;
}

.otpInputBox>form>div .otpInputt {
  justify-content: space-evenly !important;
  background-color: #000;
}

.css-13cymwt-control {
  border: none !important;
}

.css-t3ipsp-control {
  color: #212529 !important;
  background-color: #fff !important;
  border-color: #86b7fe !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25) !important;
}

/*  chat style */

.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 1px 0px;
}

.chat-bg {
  background-color: #fef3d8;
}

.attachment {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #efefef;
  display: flex;
  justify-content: center;
  align-items: center;
}

.send-button {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #28599b;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-icon {
  position: absolute;
  top: 15px;
  right: 0px;
}

.endToast {
  text-align: center;
  padding: 2rem;
  font-size: 6rem;
}

.text-fade1 {
  color: #6c757d !important;
  font-weight: 600;
}

.delOrCncl {
  background: rgba(0, 0, 0, 0.03) !important;
  border: 1px solid transparent;
  border-radius: 5px;
}

.cursor-initial {
  cursor: initial;
}

.select__single-value.css-1dimb5e-singleValue {
  padding-left: 1rem;
}

.css-1jqq78o-placeholder {
  padding-left: 1rem;
}

#react-select-7-placeholder {
  font-weight: 500;
}

/* .css-1xc3v61-indicatorContainer {
  display: none !important;
} */

@media only screen and (max-width: 350px) {
  #react-select-7-placeholder {
    font-size: 12px;
    padding-left: 0px;
    font-weight: 600;
  }


}

[class^="bi-filter"]::before,
[class*=" bi-filter"]::before {
  position: absolute;
  top: 0.7rem;
  z-index: 1;
  left: 0.5rem;
}

/* Media queries */

@media only screen and (max-width: 300px) {
  .page-link {
    padding: 6px 14px;
  }
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    padding: 0 5%;
    width: 95% !important;
  }  
}

/* Keyframes */

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  from {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate-scale-down-diag-2 {
  0% {
    -webkit-transform: scale(1) rotate3d(-1, 1, 0, 0deg);
    transform: scale(1) rotate3d(-1, 1, 0, 0deg);
  }

  50% {
    -webkit-transform: scale(0.5) rotate3d(-1, 1, 0, 180deg);
    transform: scale(0.5) rotate3d(-1, 1, 0, 180deg);
  }

  100% {
    -webkit-transform: scale(1) rotate3d(-1, 1, 0, 360deg);
    transform: scale(1) rotate3d(-1, 1, 0, 360deg);
  }
}

@keyframes rotate-scale-down-diag-2 {
  0% {
    -webkit-transform: scale(1) rotate3d(-1, 1, 0, 0deg);
    transform: scale(1) rotate3d(-1, 1, 0, 0deg);
  }

  50% {
    -webkit-transform: scale(0.5) rotate3d(-1, 1, 0, 180deg);
    transform: scale(0.5) rotate3d(-1, 1, 0, 180deg);
  }

  100% {
    -webkit-transform: scale(1) rotate3d(-1, 1, 0, 360deg);
    transform: scale(1) rotate3d(-1, 1, 0, 360deg);
  }
}